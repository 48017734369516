<!--
 * @Description: 公司
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-05 20:30:03
-->
<template>
  <el-card class="box query">
    <el-form
      :model="query"
      ref="query"
      :inline="true"
      @submit.prevent="onSearch"
    >
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="公司名称"
          style="width: 300px"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="状态">
        <el-select
          v-model="query.status"
          placeholder=""
          clearable
          style="width: 120px"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in enums.company_status"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="专员">
        <el-input v-model="query.zy_name" placeholder="专员姓名"></el-input>
      </el-form-item>

      <el-form-item label="监管浏览手机号">
        <el-select
          v-model="query.isRegulatoryShow"
          placeholder=""
          style="width: 120px"
          clearable
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item label="">
        <import-counselor></import-counselor>
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        round
        size="small"
        icon="plus"
        @click="onEdit()"
        native-type="submit"
        >新增</el-button
      >
      <el-button
        type="danger"
        round
        size="small"
        icon="delete"
        :disabled="selection_list.length <= 0"
        v-if="is_can_del"
        @click="onDeleteBatch"
        :loading="deleting"
        >批量删除</el-button
      >
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table
      ref="table"
      :data="tableData.data"
      border
      stripe
      row-key="id"
      @selection-change="onSelectionChange"
      @sort-change="onSortChange"
    >
      <el-table-column
        width="50"
        reserve-selection
        type="selection"
      ></el-table-column>
      <el-table-column
        fixed="left"
        label="ID"
        prop="id"
        width="90"
        sortable="custom"
        :resizable="false"
      ></el-table-column>
      <el-table-column
        label="公司名称"
        prop="companyName"
        width="300"
        fixed="left"
      ></el-table-column>
      <el-table-column
        label="简称"
        prop="nickname"
        width="240"
      ></el-table-column>

      <el-table-column
        label="法人姓名"
        prop="personName"
        width="100"
      ></el-table-column>
      <el-table-column
        label="法人手机"
        prop="personMobile"
        width="140"
      ></el-table-column>
      <el-table-column label="状态" width="100" prop="status_text">
        <template #default="scope">
          <el-link
            type="danger"
            size="small"
            v-if="scope.row.checkStatus == 2"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link
            type="success"
            size="small"
            v-else-if="scope.row.checkStatus == 1"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link
            type="primary"
            size="small"
            v-else-if="scope.row.checkStatus == 3"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link size="small" v-else :underline="false">{{
            scope.row.status_text
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="专员" min-width="320">
        <template #default="scope">
          <!-- {{
          scope.row.counselors
            .map(
              (x) =>
                `${x.consumerName}(${x.consumerMobile}) ${x.createTime ?? ""}`
            )
            .join("、")
        }} -->
          <div v-for="item in scope.row.counselors" :key="item.consumerId">
            {{ item.consumerName }}({{ item.consumerMobile }})
            {{ item.createTime ? "/" + item.createTime : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="账号限制"
        prop="accountCounts"
        width="100"
      ></el-table-column>
      <el-table-column label="监管查看手机" width="120">
        <template #default="scope">
          <!-- <span v-if="scope.row.isRegulatoryShow">是</span> -->
          {{ scope.row.isRegulatoryShow ? "是" : "--" }}
        </template>
      </el-table-column>
      <el-table-column
        label="注册时间"
        prop="creationTime"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            icon="view"
            @click="onPreview(scope.row)"
            >详情</el-button
          >
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >修改</el-button
          >
          <el-button
            type="primary"
            size="small"
            link
            @click="onOpenConsumer(scope.row)"
            >查看人员({{ scope.row.consumerCounts }})</el-button
          >
          <el-button
            type="success"
            size="small"
            icon="check"
            link
            v-if="scope.row.checkStatus == 0 || scope.row.checkStatus == 3"
            @click="onCheck(scope.row)"
            >审核</el-button
          >
          <el-popconfirm
            width="220"
            v-if="is_can_del"
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>

  <el-drawer
    :title="drawerTitle"
    v-model="visibleDrawer"
    direction="rtl"
    size="1200"
    :destroy-on-close="true"
    :show-close="true"
    @closed="onDrawerClosed"
  >
    <consumer is-from-company :company="currentCompany"></consumer>
  </el-drawer>
  <edit-company
    :company="current_item"
    @success="onEditSuccess"
    @closed="current_item = null"
  ></edit-company>
  <check-company
    :id="currentCheckId"
    @closed="currentCheckId = null"
    @success="loadData"
  ></check-company>
  <company-dtl :item="current_dtl" @closed="current_dtl = null"></company-dtl>
</template>

<script>
import common_api from "@/http/common_api";
import Consumer from "../consumer/index.vue";
import EditCompany from "./edit_company.vue";
import CheckCompany from "./check_company.vue";
import ImportCounselor from "./import_counselor.vue";
import CompanyDtl from "./company_dtl.vue";
export default {
  components: {
    Consumer,
    EditCompany,
    CheckCompany,
    ImportCounselor,
    CompanyDtl,
  },
  data() {
    return {
      loading: false,
      deleting: false,
      visibleDrawer: false,
      drawerTitle: "",
      enums: {},
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {
          order: "",
          prop: "",
        },
        q: "",
      },
      avatarUrl: require("@/assets/avatar.png"),
      tableData: {
        counts: 0,
      },
      currentCompany: null, //查看人员
      currentCheckId: null,
      selection_list: [],
      current_item: null, //编辑修改
      current_dtl: null, //详情
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    this.is_can_edit = this.$power("admin/v1/company/edit");
    this.is_can_del = this.$power("admin/v1/company/del");
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      //   this.current = null;
      this.loading = true;
      this.$http
        .post("/admin/v1/company", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 排序
     * @param {*} o
     */
    onSortChange(o) {
      this.query.sort = {
        order: o.order,
        prop: o.prop,
      };
      this.onSearch();
    },

    /**
     * 查看详情
     * @param {*} item
     */
    onPreview(item) {
      this.current_dtl = item;
    },

    /**
     * 编辑修改
     */
    onEdit(item) {
      this.current_item = item ? item : {};
    },

    /**
     * 多选
     */
    onSelectionChange(rows) {
      this.selection_list = rows;
    },

    /**
     * 删除
     */
    onDelete(item) {
      item.deleting = true;
      this.$http.get(`admin/v1/company/del?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          if (this.query.pageIndex > 1) {
            if (this.tableData.data.length <= 1) {
              this.query.pageIndex--;
            }
          }
          this.loadData();
        }
      });
    },

    /**
     *  批量删除
     */
    onDeleteBatch() {
      this.$confirm("您确实要删除选择的公司？", "温馨提示").then(() => {
        this.deleting = true;
        var ids = this.selection_list.map((x) => x.id);
        this.$http
          .post(`admin/v1/company/del_batch`, ids)
          .then((res) => {
            if (res.code == 0) {
              this.$refs.table.clearSelection();
              this.loadData();
            }
          })
          .finally(() => (this.deleting = false));
      });
    },

    /**
     * 查看人员账号
     * @param {*} item
     */
    onOpenConsumer(item) {
      this.visibleDrawer = true;
      this.drawerTitle = `查看【${item.companyName}】人员账号`;
      this.currentCompany = item;
    },

    /**
     * 关闭人员浏览窗口
     */
    onDrawerClosed() {
      this.currentCompanyName = "";
    },

    /**
     * 编辑成功回调
     */
    onEditSuccess() {
      this.current_item = null;
      this.loadData();
    },

    /**
     * 审核
     */
    onCheck(item) {
      this.currentCheckId = item.id;
    },

    /**
     *
     */
    onCheckSuccess() {
      this.currentCheckId = null;
      this.loadData();
    },
  },
};
</script>

<style scoped></style>
